import { createStore } from "vuex"

const store = createStore({
    state:{
        enteredWeb: "",
        responses:[],

        questions: [
            {
                id: 0,
                questionType: [
                    {
                        option: "a",
                        feedback: "",
                        content:"What would you like to do?",
                        image:"images/start.gif",
                        link:"",
                        tip:"",
                        answers: {
                            a: "Find a co-op",
                            b: "Find a person in a co-op",
                            c: "Access resources",
                            d: "Review proposals",
                            e: "Access General Circle video call",
                            f: "Report concern",
                            g: "Message someone",
                            h: "See or make announcement",
                            i: "Share notes",
                            j: "Discuss",
                        },

                    },
                    {
                        option: "b",
                        feedback: "",
                        content: "",
                        image:"",
                        link:"",
                        tip:"",
                        answers: {},
                    }
                ]

            },
            {
                id: 1,
                questionType: [
                    {
                        option: "a",
                        feedback:"Searching for a co-op in CoTech?",
                        content: "It's best to go to our main website and scroll away!",
                        image:"images/2.gif",
                        link:"https://coops.tech",
                        tip:"Each co-op's page has more information, including contact details.",
                        answers: {},

                    },
                    {
                        option: "b",
                        feedback: "Looking for a person to speak to?",
                        content:"The easiest way to reach them is via Slack.",
                        image:"images/4.gif",
                        link:"https://tech-coops.slack.com/",
                        tip:"If you don't have an account yet, just follow the link above and click 'Create an account' in top-right corner.",
                        answers: {},

                    },
                    {
                        option: "c",
                        feedback: "Looking for some resources?",
                        content:"You can find all the CoTech information on our wiki.",
                        image:"images/1.gif",
                        link:"https://wiki.coops.tech/",
                        tip:"Ask on Slack if you don't have an account yet.",
                        answers: {},

                    },
                    {
                        option: "d",
                        feedback: "Looking to review proposals?",
                        content:"We use Loomio for making decisions.",
                        image:"images/2.gif",
                        link:"https://www.loomio.org/cotech/",
                        tip:"You can request to join on the page by clicking 'Join Group'.",
                        answers: {},

                    },
                    {
                        option: "e",
                        feedback: "Looking for the General Circle call link?",
                        content:"We use Meet.coop platform for our calls, jump in from your browser.",
                        image:"images/5.gif",
                        link:"https://ca.meet.coop/b/chr-lna-sxq",
                        tip:"Search BigBlueButton to learn more about the underlying software.",
                        answers: {},

                    },
                    {
                        option: "f",
                        feedback: "Looking to speak to someone about an incident?",
                        content:"We are currently passing our code of conduct and establishing a dedicated support liaison.",
                        image:"images/5.gif",
                        link:"https://www.loomio.org/d/HglhQvGZ/adopt-code-of-conduct-for-cotech",
                        tip:"",
                        answers: {},

                    },
                    {
                        option: "g",
                        feedback: "Looking to message someone from CoTech?",
                        content:"Our preferred way for instant messaging Slack.",
                        image:"images/3.gif",
                        link:"https://tech-coops.slack.com/",
                        tip:"If you don't have an account yet, just follow the link and click 'Create an account' in top-right corner.",
                        answers: {},

                    },
                    {
                        option: "h",
                        feedback: "Interested in announcements?",
                        content:"We use Discourse forum to follow and make public announcements.",
                        image:"images/5.gif",
                        link:"https://community.coops.tech/",
                        tip:"You can also create private threads if you are registered with a CoTech co-op address.",
                        answers: {},

                    },
                    {
                        option: "i",
                        feedback: "Looking to share notes?",
                        content:"We hold notes and minutes on our wiki.",
                        image:"images/1.gif",
                        link:"https://wiki.coops.tech/",
                        tip:"Ask on Slack if you don't have an account yet.",
                        answers: {},

                    },
                    {
                        option: "j",
                        feedback: "Looking to get involved in a discussion?",
                        content:"Discourse forum is a great space for engaging in wide-ranging conversations.",
                        image:"images/3.gif",
                        link:"https://community.coops.tech/",
                        tip:"You can also create private threads if you are registered with a CoTech co-op address.",
                        answers: {},
                    }
                ]

            },


        ]
    },

    getters: {
        enteredWeb: (state) => state.enteredWeb,
        questions: (state) => state.questions,
        responses: (state) => state.responses,
    },

    mutations: {
        SET_entered(state, value) {
            state.enteredWeb = value
        },
        SAVE_response(state, value) {
            state.responses.push(value)
        },
    },

    actions: {
        setEntered(context, value) {
            context.commit("SET_entered", value)
        },
        saveResponse(context, value) {
            context.commit("SAVE_response", value)
        }
    },

})

export default store
