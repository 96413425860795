<template>
  <router-view/>

</template>

<script>



export default {
  components: {
  },
  setup(){

  }



}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
